.about-work {
    width: 100%;
}

.about-work_background {
    background-color: #fff;
}

.about-work_background-top {
    width: 100%;
    height: 40px;
    background-image: url(../../pics/background-top_white.svg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 200%;
    overflow: hidden;
    margin: 0;
}

.about-work_background-down {
    width: 100%;
    height: 40px;
    background-image: url(../../pics/background-down_white.svg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 200%;
    overflow: hidden;
    margin: 0;
}


.about-work_tables {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 50px 0 0 0;
    transition: .3s ease-in-out;
}

.about-work_experience {
    width: 89%;
    max-width: 1150px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
    transition: .3s ease-in-out;
}

.about-work_years {
    width: 40%;
    margin: 0;
}

.about-work_years:last-of-type {
    margin: 0;
}

.about-work_header {
    font-size: 3.2rem;
    font-family: 'Gilroy';
    line-height: 3.2rem;
    font-weight: 900;
    color: #881EF2;
    text-transform: uppercase;
    margin: 40px 0 0 0;
}

.about-work_table {
    width: 100%;
}

.about-work_skills {
    width: 89%;
    max-width: 1150px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 50px 0 50px 0;
}

.about-work_skills-table {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width: 1240px) {
    .about-work_experience {
        width: 80%;
    }

    .about-work_years {
        width: 45%;
    }
}

@media screen and (max-width: 800px) {
    .about-work_experience {
        width: 80%;
        flex-direction: column;
    }

    .about-work_years {
        width: 100%;
    }

    .about-work_header__work {
        margin-top: 150px;
    }

    .about-work_skills-table {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .about-work_skills {
        width: 80%;
    }

    .about-work_background-top {
        height: 30px;
    }

    .about-work_background-down {
        height: 30px;
    }
}