.all-work {
    width: 80%;
    max-width: 1150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 70px auto;
    transition: .5s ease-in-out;
}

.all-work-background {
    background-color: #fff;
    transition: .5s ease-in-out;
}

.all-work_background-top {
    width: 100%;
    height: 40px;
    background-image: url(../../pics/background-top_white.svg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 200%;
    overflow: hidden;
    margin: 100px 0 0 0;
    transition: .5s ease-in-out;
}

.all-work_background-bottom {
    width: 100%;
    height: 40px;
    background-image: url(../../pics/background-down_white.svg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 200%;
    overflow: hidden;
    margin: 0;
    transition: .5s ease-in-out;
}

.all-work_menu {
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.all-work_menu-link {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-family: 'Gilroy';
    font-weight: 100;
    color: #000;
    text-align: left;
    text-decoration: none;
    background-color: #fff;
    padding: 0 0 0 7px;
    margin: 20px 0 0 0;
    cursor: url('../../pics/coursor_link.svg'), auto;
}

.all-work_menu-link_active {
    font-weight: 900;
    background-color: #A3F937;
}

.all-work_menu-link:hover {
    font-weight: 900;
}

.all-work_block {
    width: 77%;
    max-width: 897px;
    transition: .5s ease-in-out;
}

.all-work_header {
    font-size: 3.2rem;
    font-weight: 900;
    font-family: 'Gilroy';
    line-height: 3.2rem;
    text-align: left;
    color: #A3F937;
}

.all-work_cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:flex-start;
    margin: 0;
    padding: 0 ;
    transition: .5s ease-in-out;
}

@media screen and (max-width: 1240px) {
    .all-work_menu-link {
        font-size: 1.3rem;
        line-height: 1.3rem;
        transition: .3s ease-in-out;
    }

    .all-work_block {
        width: 70%;
    }
    
}

@media screen and (max-width: 800px) {
    .all-work {
        width: 100%;
    }

    .all-work_menu {
        display: none;
    }

    .all-work_block {
        width: 100%;
    }

    .all-work_background-top {
        height: 30px;
        margin-top: 120px;
    }

    .all-work_background-bottom {
        background-size: 300%;
    }
}