@font-face {
    font-family: "Gilroy";
    src: url(Gilroy-Regular.woff) format("woff"),
        url(Gilroy-Regular.woff2) format("woff2");
    font-weight: 100;
    font-style: normal;
    }

@font-face {
    font-family: "Gilroy";
    src: url(Gilroy-Heavy.woff) format("woff"),
        url(Gilroy-Heavy.woff2) format("woff2");
    font-weight: 900;
    font-style: normal;
    }
