
.dolfin_title {
    margin-top: 30px;
}

.dolfin_cover {
    width: 100%;
}

.dolfin_task-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/dolfin/solution.png);
}

.dolfin_pack {
    width: 100%;
    margin: 100px 0 0 0;
}

.dolfin_mockup {
    width: 100%;
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .dolfin_pack {
        width: 80%;
    }

    .dolfin_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .dolfin_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }
}