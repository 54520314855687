.case-page_background-bottom {
    width: 100%;
    height: 40px;
    background-image: url(../../pics/background-down_white.svg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 200%;
    overflow: hidden;
    margin: 0;
}

@media screen and (max-width: 800px) {
    .case-page_background-bottom {
        background-size: 300%;
    }
}