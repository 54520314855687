
.fungoterbin_title {
    margin-top: 30px;
}

.fungoterbin_cover {
    width: 100%;
    }

.fungoterbin_task-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/fungoterbin/task.png);
    background-position: left;
}

.fungoterbin_pack {
    width: 100%;
    margin: 100px 0 0 0;
}

.fungoterbin_mockup {
    width: 100%;
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .fungoterbin_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .fungoterbin_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }
}