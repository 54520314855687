.case-page {
    width: 89%;
    max-width: 1150px;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 70px auto;
    transition: .3s ease-in-out;
}

.case-page-background {
    background-color: #fff;
}

.case-page_section {
    width: 78%;
}

.case-page_image {
    width: 100%;
    min-height: 587px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: .3s ease-in-out;
}

.case-page__title {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-family: 'Gilroy';
    font-weight: 900;
    color: #000;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 0 10px 0;
}

.case-page__subtitle {
    font-size: 1rem;
    line-height: 1.3rem;
    font-family: 'Gilroy';
    font-weight: 100;
    color: #000;
    text-align: left;
    text-decoration: none;
    margin: 0 0 15px 0;
}

.case-page__block {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 100px 0 0 0;
    transition: .3s ease-in-out;
}

.case-page__text {
    width: 50%;
    margin: 0;
}

.case-page__subtitle:last-of-type {
    margin: 0;
}

.case-page__solution {
    margin: 0 0 0 40px;
}

@media screen and (max-width: 1240px) {
    .case-page {
        width: 80%;
    }

    .case-page_section {
        width: 100%;
    }

    .case-page_image {
        width: 100%;
        min-height: 500px;
        z-index: 50;
    }
    
    .case-page__task {
        margin: 0 0 0 40px;
    }
}

@media screen and (max-width: 800px) {
    .case-page_section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .case-page {
        width: 100%;
    }

    .case-page_image {
        width: 100%;
        min-height: 400px;
        z-index: 50;
    }

    .case-page__block {
        width: 80%;
        flex-direction: column;
        align-items: center;
        margin: 100px auto 0;
    }

    .case-page__title {
        margin-top: 100px;
    }

    .case-page__title__solution {
        margin: 0;
    }

    .case-page__text {
        width: 100%;
        margin: 0;
    }

}