
.rogozhsky_title {
    margin-top: 30px;
}

.rogozhsky_cover {
    width: 100%;
    margin: 0;}

.rogozhsky_task-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/rogozhsky/task.png);
}

.rogozhsky_solution-pic {
    width: 50%;
    min-height: 245px;
    background-image: url(../../pics/cases-pics/rogozhsky/solution.png);
}

.rogozhsky_pack {
    width: 100%;
    margin: 100px 0 0 0;
}

.rogozhsky_mockup {
    width: 100%;
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .rogozhsky_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .rogozhsky_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }

    .rogozhsky_title {
        margin-top: 100px;
    }

}
