
.dom-22_cover {
    width: 100%;
}

.dom-22_task-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/dom-22/task.png);
}

.dom-22_solution-pic {
    width: 50%;
    min-height: 245px;
    background-image: url(../../pics/cases-pics/dom-22/solution.png);
}

.dom-22_pack__status {
    width: 100%;
    margin: 100px 0 0 0;
}

.dom-22_pack__location {
    width: 100%;
    margin: 100px 0 0 0;
}

.dom-22_mockup {
    width: 100%;
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .dom-22_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .dom-22_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }
}