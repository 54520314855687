
.clients-logos_background-top {
    width: 100%;
    height: 40px;
    background-image: url(../../pics/background-top_white.svg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 200%;
    overflow: hidden;
    margin: 100px 0 0 0;
    transition: .5s ease-in-out;
}

.clients-logos_background-bottom {
    width: 100%;
    height: 40px;
    background-image: url(../../pics/background-down_white.svg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 200%;
    overflow: hidden;
    margin: 0;
    transition: .5s ease-in-out;
}

.clients-logos_background {
    background-color: #fff;
    transition: .5s ease-in-out;
    padding: 100px 0;
}

.clients-logos-table {
    max-width: 80%;
    margin: 0 auto 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.clients-logos_logo-block {
    width: 23%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .5s ease-in-out;
}

.clients-logos_logo {
    max-width: 200px;
    transition: .5s ease-in-out;
}

.clients-logos_small-logo {
    max-width: 150px;
    transition: .5s ease-in-out;
}


@media screen and (max-width: 1240px) {
    .clients-logos_logo-block {
        width: 30%;
    }

    .clients-logos_logo {
        max-width: 150px;
    }
    
    .clients-logos_small-logo {
        max-width: 100px;
    }
}

@media screen and (max-width: 800px) {
    .clients-logos_background-bottom {
        background-size: 300%;
    }

    .clients-logos_logo-block {
        width: 45%;
    }

    .clients-logos_logo {
        max-width: 150px;
    }
    
    .clients-logos_small-logo {
        max-width: 100px;
    }
}