.header {
    display: flex;
    transition: .5s ease-in-out;
    text-decoration: none;
    color: #fff;
    z-index: 100;
}

.header_column {
    height: 80vh;
    width: 80%;
    max-width: 1150px;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    margin: 0 auto;
    transition: .3s ease-in-out;
}

.header_row {
    width: 89%;
    max-width: 1150px;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: right;
}

.header_logo {
    width: 35%;
    height: 100px;
    margin: 0;
}

.header__image {
    width: 100%;
    cursor: url('../../pics/coursor_link.svg'), auto;
}

.header__links-main {
    margin: auto 0;
    padding: 0 3px;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.header__links-small {
    margin: 63px 0 0 0;
    max-height: 27px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: left;
}

.header__link {
    font-family: 'Gilroy';
    font-weight: 900;
    color: #fff;
    text-decoration: none;
}

.header__link:hover {
    color: #000;
    background-color: #A3F937;
    cursor: url('../../pics/coursor_link.svg'), auto;
}

.header__link-main {
    margin-top: 20px;
    font-size: 3.5rem;
    transition: .3s ease-in-out;
}

.header__link-small {
    margin: 0 45px 0 0;
    padding: 0 3px;
    font-size: 1.5rem;
    padding: 0 0 0 5px;
    transition: .3s ease-in-out;
}

.header__nav-button {
    width: 35px;
    min-height: 35px;
}

.header__link-small:last-of-type {
    margin: 0;
}

.active {
    color: #000;
    background-color: #A3F937;
}


@media screen and (max-width: 1240px) {
    .header_column {
        width: 80%;
    }

    .header_row {
        width: 80%;
    }

    .header_logo {
        width: 35%;
        height: 100px;
        margin: 0;
    }

    .header__link-main {
        margin-top: 20px;
        font-size: 2.5rem;
    }

    .header__link-small {
        margin: 0 10px 0 0;
        font-size: 1.2rem;
    }

    .header__link-small:last-of-type {
        margin: 0;
    }
}

@media screen and (max-width: 800px) {
    .header_logo {
        width: 100%;
        height: 170px;
        background-color: rgb(0, 0, 0, 0);
    }

    .header_column {
        width: 80%;
        height: 80vh;
        flex-direction: column;
        justify-content: flex-start;
    }

    .header__links-main {
        margin: auto 0;
    }

    .header__link {
        width: 100%;
        font-size: 3rem;
        text-align: center;
    }

    .header_row {
        width: 80%;
        flex-direction: column;
        align-items: center;
    }

    .header__links-small {
        width: 100%;
        margin: 0 0 30px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        
    }

    .header__link-small {
        width: 40%;
        height: 45px;
        margin: 20px 0 0 0;
        font-size: 1.6rem;
        line-height: 3.2rem;
    }

    .header__link-small_clients {
        line-height: 1.4rem;
    }

    .header__link-small:last-of-type {
        margin: 20px 0 0 0;
    }
}