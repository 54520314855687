
.svoi_title {
    margin-top: 30px;
}

.svoi_cover {
    width: 100%;
}

.svoi_task-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/svoi/task.png);
}

.svoi_solution-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/svoi/solution.png);
}

.svoi_pack {
    width: 100%;
    margin: 100px 0 0 0;
}

.svoi_mockup {
    width: 100%;
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {
    .svoi_mockup {
        min-height: 700px;
        background-position: top;
        margin: 100px 0 0 0;
    }
}

@media screen and (max-width: 800px) {
    .svoi_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .svoi_solution-pic {
        width: 100%;
        min-height: 300px;
    }

    .svoi_pack {
        width: 80%;
        margin: 100px 0 0 0;
    }
}
