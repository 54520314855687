.footer {
    width: 100%;
    background-color: #881EF2;
    margin: auto 0;
    transition: .5s ease-in-out;
}

.footer__container_main {
    width: 80%;
    max-width: 1150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto 20px auto;
    transition: .5s ease-in-out;
}

.footer__container_small {
    width: 80%;
    max-width: 1150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    margin: 0 auto;
    transition: .5s ease-in-out;
}

.footer__disclaimer {
    max-width: 100%;
    font-size: .8rem;
    vertical-align: bottom;
    font-weight: 300;
    text-align: left;
    align-items: center;
    color: #fff;
    margin: 79px 13px 0 0;
}

.footer__line_main {
    width: 85%;
    min-height: 128px;
    background-image: url(../../pics/dog_line.png);
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    transition: .3s ease-in-out;
}

.footer__line_small {
    width: 70%;
    height: 1px;
    background-color: #fff;
    margin: auto 0 27px;
}

.footer__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 79px 0 20px 13px;
}

.footer__link {
    color: #fff;
    font-size: .8rem;
    font-weight: 300;
    text-align: right;
    text-decoration: none;
    margin: 0 38px 0 0;
    cursor: url('../../pics/coursor_link.svg'), auto;
}

.footer__link:last-of-type {
    margin: 0;
}

@media screen and (max-width: 1240px) {
    .footer__container_main {
        width: 80%;
    }

    .footer__line_main {
        min-height: 150px;
        width: 85%;
        background-image: url(../../pics/dog_line_medium.png);
        display: flex;
        flex-direction: column;
        background-position: bottom;
    }

    .footer__disclaimer {
        width: 190px;
        margin: 79px 0 20px 13px;
    }

    .footer__line_small {
        width: 80%;
        height: 1px;
        background-color: #fff;
        margin: auto 0 27px;
    }
}

@media screen and (max-width: 800px) {
    .footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer__container_main {
        width: 80%;
        margin: 0;
        flex-direction: column-reverse;
        align-items: center;
    }

    .footer__container_small {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .footer__line_main {
        min-height: 150px;
        width: 100%;
        background-image: url(../../pics/dog_line_small.png);
        display: flex;
        flex-direction: column;
        background-position: bottom;
        overflow: hidden;
    }

    .footer__link {
        margin: 0 20px 0 0;
    }

    .footer__line_small {
        width: 100%;
    }

    .footer__disclaimer {
        text-align: center;
        width: 210px;
        height: 15px;
        margin: 30px 0 27px 0;
    }

    .footer__nav {
        margin: 0 0 50px 0;
    }
}