
.frautest_title {
    margin-top: 30px;
}

.frautest_cover {
    width: 100%;
}

.frautest_task-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/frautest/task.png);
}

.frautest_solution-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/frautest/solution.png);
}

.frautest_pack {
    width: 100%;
    margin: 100px 0 0 0;
}

.frautest_mockup {
    width: 100%;
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .frautest_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .frautest_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }

}