
.st-pet__cover {
    width: 100%;
}

.st-pet__task-pic {
    width: 50%;
    min-height: 245px;
    background-image: url(../../pics/cases-pics/st-pet/task.png);
    background-position: left;
}

.st-pet__solution-pic {
    width: 50%;
    min-height: 245px;
    background-image: url(../../pics/cases-pics/st-pet/solution.png);
}

.st-pet__pack {
    width: 100%;
    margin: 100px 0 0 0;
}

.st-pet__mockup {
    width: 100%;
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .st-pet__task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .st-pet__solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 0;
    }

    .st-pet__title {
        margin-top: 0;
    }

    .st-pet__pack {
        width: 80%;
    }
}
