
.mobiado-outdoor_title {
    margin-top: 30px;
}

.mobiado-outdoor_cover {
    width: 100%;
}

.mobiado-outdoor_task-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/mobiado-outdoor/task.png);
}

.mobiado-outdoor_image_1 {
    width: 100%;
    margin: 100px 0 0 0;
}

.mobiado-outdoor_image_2 {
    width: 100%;
    margin: 100px 0 0 0;
}

.mobiado-outdoor_mockup {
    width: 100%;
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .mobiado-outdoor_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .mobiado-outdoor_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }
}