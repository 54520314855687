
.mayak-video_title {
    margin-top: 50px;
}

.mayak-video_cover {
    width: 100%;
}

.mayak-video_task-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/mayak-video/task.png);
}

.mayak-video_video {
    margin: 100px 0;
    min-width: 100%;
    min-height: 500px;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .mayak-video_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .mayak-video_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }
}