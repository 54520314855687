
.volost__cover {
    width: 100%;
}

.volost__task-pic {
    width: 50%;
    min-height: 245px;
    background-image: url(../../pics/cases-pics/volost/task.png);
    background-position: left;
}

.volost__solution-pic {
    width: 50%;
    min-height: 245px;
    background-image: url(../../pics/cases-pics/volost/solution.png);
}

.volost__pack {
    width: 100%;
    margin: 100px 0 0 0;
}

.volost__mockup {
    width: 100%;
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .volost__task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .volost__solution-pic {
        width: 100%;
        min-height: 300px;
        margin-bottom: 100px;
    }

    .volost__title {
        margin: 0;
    }

    .case-page__solution {
        margin: 0 0 100px 0;
    }
}
