
.sber-strahovaniye_cover {
    width: 100%;
    height: 587px;
}

.sber-strahovaniye_task-pic {
    width: 50%;
    min-height: 245px;
    background-image: url(../../pics/cases-pics/sber-strahovaniye/solution.png);
}

.sber-strahovaniye_pack__status {
    width: 100%;
    margin: 100px 0 0 0;
}

.sber-strahovaniye_video {
    margin-top: 100px;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .sber-strahovaniye_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .sber-strahovaniye_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }
}