
.movie-video_title {
    margin-top: 30px;
}

.movie-video_cover {
    width: 100%;
}

.movie-video_task-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/movie-video/task.png);
}

.movie-video_video-block {
    margin: 100px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.movie-video_video {
    margin: 0 0 60px 0;
    min-width: 47%;
    min-height: 300px;
}

.movie-video_mockups  {
    width: 100%;
    min-height: 500px;
    margin: 0px 0 50px 0;
}

.movie-video_mockup-1 {
    width: 100%;
    margin: 0;
}

.movie-video_mockup-2 {
    width: 100%;
    margin: 0;
}

.movie-video_mockup-3 {
    width: 100%;
    margin: 0;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .movie-video_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .movie-video_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }

    .movie-video_title {
        margin: 0;
    }
}