
.amigo_title {
    margin-top: 50px;
}

.amigo_cover {
    width: 100%;
}

.amigo_task-pic {
    background-position: top;
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/amigo/solution.png);
    background-position: center;
}

.amigo_pack {
    width: 100%;
    margin: 100px 0 0 0;
}

.amigo_landing {
    width: 100%;
    margin: 100px 0 0 0;
}

.amigo_mockup {
    width: 100%;
    height: 600px;
    margin: 100px 0 0 0;
}

.amigo_video {
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {
    .amigo_mockup {
        height: 450px;
    }
}

@media screen and (max-width: 800px) {
    .amigo_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .amigo_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }

    .amigo_pack {
        width: 80%;
        margin: 100px auto 0;
    }
}