
.clean-line_title {
    margin-top: 30px;
}

.clean-line_cover {
    width: 100%;
}

.clean-line_task-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/clean-line/task.png);
}

.clean-line_pack {
    width: 100%;
    margin: 100px 0 0 0;
}

.clean-line_mockup {
    width: 100%;
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .clean-line_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .clean-line_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }
}