.about-me {
    width: 89%;
    max-width: 1150px;
    display: flex;
    flex-direction: row;
    margin: 150px auto;
    transition: .3s ease-in-out;
}

.about-me_image {
    width: 32%;
    min-width: 359px;
    min-height: 100%;
    background-image: url(../../pics/photo.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 0 0 10px;
}

.about-me_description {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 92px;
}

.about-me_header {
    font-size: 3.2rem;
    line-height: 3.2rem;
    font-weight: 900;
    color: #A3F937;
    text-transform: uppercase;
    margin: 0;
}

.about-me_text {
    font-size: 1rem;
    font-weight: 100;
    color: #fff;
    margin: 18px 0 0;
}

.about-me_text:first-of-type {
    margin: 35px 0 0;
}

@media screen and (max-width: 1240px) {
    .about-me {
        width: 80%;
    }

    .about-me_text {
        font-size: 1rem;
    }

    .about-me_description {
        margin: 0 0 0 70px;
    }
}

@media screen and (max-width: 800px) {
    .about-me {
        flex-direction: column;
        flex-wrap: wrap;
        margin: 100px auto;
    }

    .about-me_image {
        width: 100%;
        min-height: 500px;
        margin: 0;
    }

    .about-me_description {
        margin: 100px 0 0 0;
    }
}

@media screen and (max-width: 500px) {
    .about-me {
        width: 100%;
        align-items: center;
    }

    .about-me_description {
        width: 80%;
        margin: 100px 0 0 0;
    }

    .about-me_image {
        margin-top: 30px;
    }
}