.client-popup_block {
    width: 100%;
    min-height: 100vh;
    background-color: rgb(0, 0, 0, 0.53);
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: .3s ease-in-out;
}

.client-popup_opened {
    visibility: visible;
    opacity: 1;
}

.client-popup {
    width: 501px;
    min-height: 233px;
    background-image: url(../../pics/popup.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: .3s ease-in-out;
}

.client-popup_close-button {
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    width: 22px;
    min-height: 22px;
    background-image: url(../../pics/close-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    left: 480px;
    top: -100px;
    cursor: url('../../pics/coursor_link.svg'), auto;
    transition: .3s ease-in-out;
}

.client-popup_text {
    width: 82%;
    font-size: 1.5rem;
    font-weight: 100;
    font-family: 'Gilroy';
    line-height: 1.7rem;
    color: #000;
    text-align: left;
    text-decoration: none;
    transition: .3s ease-in-out;
}

@media screen and (max-width: 800px) {
    .client-popup {
        width: 300px;
        min-height: 200px;
    }

    .client-popup_text {
        width: 82%;
        font-size: 1rem;
        line-height: 1rem;
        margin: 0;
    }

    .client-popup_close-button {
        width: 22px;
        min-height: 22px;
        left: 300px;
        top: -50px;
    }
}