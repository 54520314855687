
.azhur_cover {
    width: 100%;
    height: 587px;
}

.azhur__work {
    width: 100%;
    margin: 100px 0 0 0;
}

.azhur_video {
    margin: 60px 0;
    min-width: 100%;
    min-height: 500px;
}


@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .azhur_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .azhur_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }
}