
.aist-residence_title {
    margin-top: 30px;
}

.aist-residence_cover {
    width: 100%;
}

.aist-residence_task-pic {
    width: 50%;
    min-height: 145px;
    background-image: url(../../pics/cases-pics/aist-residence/task.png);
}

.aist-residence_pack {
    width: 100%;
    margin: 100px 0 0 0;
}

.aist-residence_mockup {
    width: 100%;
    margin: 100px 0 0 0;
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .aist-residence_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .aist-residence_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }

    .aist-residence_mockup {
        min-height: 1000px;
    }
}