.work-card {
    width: 31%;
    height: 290px;
    margin-bottom: 2%;
    margin-left: 2%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.work-card_link {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-decoration: none;
    cursor: url('../../pics/coursor_link.svg'), auto;
}

.work-card_text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-end;
    text-decoration: none;
    opacity: 0;
    transition: .5s ease-in-out;
}

.work-card__title {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-family: 'Gilroy';
    font-weight: 900;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 0 7px 10px;
}

.work-card__subtitle {
    font-size: 1rem;
    line-height: 1rem;
    font-family: 'Gilroy';
    font-weight: 100;
    color: #fff;
    text-decoration: none;
    margin: 0 0 10px 10px;
}

.work-card_text:hover {
    background-color: rgba(0,0,0,0.4);
    opacity: 1;
}

@media screen and (max-width: 1240px) {
    .work-card {
        width: 47%;
        height: 290px;
    }
}

@media screen and (max-width: 800px) {
    .work-card {
        width: 48%;
        height: 320px;
    } 
}

@media screen and (max-width: 800px) {
    .work-card {
        width: 100%;
        height: 320px;
    }

    .work-card__title {
        margin: 0 0 7px 20px;
    }

    .work-card__subtitle {
        margin: 0 0 20px 20px;
    }
}

