.illustration {
    overflow: hidden;
    width: 70%;
    height: 100%;
    background-image: url(../../pics/main.png);
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 101;
    position: absolute;
    display: block;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    transition: .3s ease-in-out;
}

.illustration__small {
    overflow: hidden;
    z-index: 5;
    width: 55%;
    height: 100%;
    bottom: 0; 
}

@media screen and (max-width: 1240px) {
    .illustration {
        width: 65%;
        height: 100%;
        background-position: left;
    }

    .illustration__small {
        width: 55%;
        height: 100%;
        bottom: 0;
        right: 0;
    }
}

@media screen and (max-width: 800px) {
    .illustration {
        display: none;
    }

    .illustration__small {
        display: none;
    }
}